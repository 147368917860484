:root {
  --color-primary: #7d60f5;
  --color-primary-hover: #7868c3;
  --color-secondary: #f5f5f5;
  --color-success: #4dc06a;
  --color-danger: #f55d5d;
  --color-text: #515151;
  --color-link: #1855db;
  --color-link-hover: #3b86ff;
  --color-icon: #808080;
}

* {
  font-family: Nunito, Helvetica Neue, Helvetica, Arial, sans-serif;
}

html {
  overflow-x: hidden;
  background-color: #eeeeee;
}

body {
  color: var(--color-text);
  margin: 0;
  padding: 0;
  font-size: 14px;
  .containerModal {
    bottom: 0;
    right: 0;
    left: initial !important;
    top: initial !important;
    height: calc(100% - 55px);
    width: calc(25% - 15px);
    @media (max-width: 767px) {
      width: 100%;
    }
  }
}
.link {
  color: var(--color-link);
  cursor: pointer;
  &:hover {
    color: var(--color-link-hover);
  }
}

[class^="icon-"],
[class*=" icon-"] {
  color: var(--color-text);
}

.seek_atention {
  transition: all 0.4s ease;
  animation: seekAttention 1s ease-in infinite;
}

@keyframes seekAttention {
  0% {
    outline: 4px solid transparent;
  }
  75% {
    outline: 4px solid var(--color-primary);
  }
  100% {
    outline: 4px solid transparent;
  }
}

.animateOut {
  animation: animateOut 0.5s ease-in;
  transform: translateX(0);
}

@keyframes animateOut {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(100%);
  }
}

.base_container {
  max-width: 800px;
  margin: 0 auto;
  @media screen and (max-width: 820px) {
    max-width: 100%;
    padding: 0 1.5rem;
  }
  @media screen and (max-width: 1440px) {
    max-width: 600px;
  }
}

.base_bg {
  background-color: #eee;
}

.chakra-alert {
  background-color: white !important;
  border-inline-start-color: #1ABC9C !important;
  * {
    color: var(--color-text);
  }
  path {
    fill: #1ABC9C !important;
  }
}

.chakra-popover__popper {
  z-index: 99999 !important;
}
.link-editor {
  z-index: 99999 !important;
}
#response-module-placeholder {
  li {
    margin-left: 2rem;
  }
}

.conversation-view .chakra-toast__inner {
  .chakra-alert {
    background-color: var(--color-danger) !important;
    border-radius: 8px;
    padding: 1rem 1.3rem;
      * path {
        fill: white !important;
      }
    .chakra-alert__icon {
      margin-right: .5rem;
      width: 20px;
      height: 20px;
    }
    * {
      color: white;
      font-weight: bold;
    }
    button {
      svg {
        margin: 4px;
        height: 10px;
      }
    }
  }
}
